(ns io.klei.lms.frontend.feature.student.profile-sections
  (:require
    [antd]
    [clojure.set :as set]
    [clojure.string :as string]
    [malli.core :as m]
    [pyramid.core :as p]
    [goog.string :as g.string]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as g.object]
    ["react-responsive" :as responsive]
    ["signature_pad$default" :as SignaturePad]
    ["react-signature-canvas$default" :as react-signature]
    [taoensso.timbre :as log]
    [io.klei.lms.frontend.feature.pdf-viewer :as f.pdf-viewer]
    [io.klei.lms.frontend.shared.config :as config]
    [io.klei.lms.frontend.feature.update-unique-value :as f.unique]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.form.core :as f]
    [malli.core :as malli]
    [malli.util :as malli.util]
    [io.klei.lms.frontend.entity.student :as e.student]
    [io.klei.lms.frontend.entity.registration :as e.registration]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.page.user.index :as p.user.index]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn- sort-registrations [regs]
  (->> regs
       (map second)
       (sort-by (comp - :registration/date))))

(defn- selected-registration [state registrations]
  (get registrations (:selected-registration-id state)))

(defn- section-container [children]
  [ui/row
   [ui/col {:xs 24 :md 16}
    children]])

(defmulti render-section (fn [form student] (:name form)))

(defmethod render-section "user-account"
  [_form student]
  (r/as-element [section-container
                 [p.user.index/user-edit-form {:user (-> student :auth/user)}]]))

(defmethod render-section "download-forms"
  [form student]
  (r/with-let [params {:user-id (-> student :auth/user :user/id)}
               registrations (rf/subscribe [:entity.student.sub/registrations (:student/id student)])
               state (r/atom {:selected-registration-id (:registration/id (first (sort-registrations @registrations)))})]
    (r/as-element
      [ui/row
       [ui/col
        [ui/title {:level 3} "Forms"]
        [ui/radio-group {:value (:selected-registration-id @state)
                         :onChange (fn [e]
                                     (swap! state assoc :selected-registration-id (.. e -target -value)))}
         (into [ui/space {:direction "vertical"}]
           (map (fn [reg]
                  [ui/radio {:value (:registration/id reg)} (e.registration/registration-radio-text reg)])
                (sort-registrations @registrations)))]
        [:p]
        (let [params (assoc params :registration-id (:selected-registration-id @state))]
          [:<>
           [:p [:a {:href (utils/form-url "deped-annex-1" params)} "DepEd Annex-1"]]
           [:p [:a {:href (utils/form-url "deped-annex-3" params)} "DepEd Annex-3"]]
           [:p [:a {:href (utils/form-url "agca-enrollment-form" params)} "AGCA Enrollment Form"]]
           [:p [:a {:href (utils/form-url "vecc-enrollment-form" params)} "VECC Enrollment Form"]]
           [:p [:a {:href (utils/form-url "srk-texas-enrollment-form" params)} "SRK Texas Enrollment Form"]]
           [:p [:a {:href (utils/form-url "srk-wyoming-enrollment-form" params)} "SRK Wyoming Enrollment Form"]]
           [:p [:a {:href (utils/form-url "srk-registration-form" params)} "SRK Registration Form"]]
           [:div [:b "Dubai"]
            [:p [:a {:href (utils/form-url "srk-registration-form-dubai" params)} "SRK Registration Form - Dubai"]]
            [:p [:a {:href (utils/form-url "srk-undertaking-dubai" params)} "SRK Undertaking - Dubai"]]]
           [:div [:b "Electra"]
            [:p [:a {:href (utils/form-url "srk-registration-form-electra" params)} "SRK Registration Form - Electra"]]
            [:p [:a {:href (utils/form-url "srk-undertaking-electra" params)} "SRK Undertaking - Electra"]]]
           [:div [:b "Hamdan"]
            [:p [:a {:href (utils/form-url "srk-registration-form-hamdan" params)} "SRK Registration Form - Hamdan"]]
            [:p [:a {:href (utils/form-url "srk-undertaking-hamdan" params)} "SRK Undertaking - Hamdan"]]]
           [:div [:b "Al Ain"]
            [:p [:a {:href (utils/form-url "srk-registration-form-al-ain" params)} "SRK Registration Form - Al Ain"]]
            [:p [:a {:href (utils/form-url "srk-undertaking-al-ain" params)} "SRK Undertaking - Al Ain"]]]
           [:div [:b "Woodlands"]
            [:p [:a {:href (utils/form-url "woodlands-srk-enrollment-agreement" params)} "Woodlands SRK Enrollment Agreement Form"]]
            [:p [:a {:href (utils/form-url "woodlands-student-registration-form" params)} "Woodlands Student Registration Form"]]]
           [:div [:b "Happy Learners Academy"]
            [:p [:a {:href (utils/form-url "hla-enrollment-form" params)} "Enrollment Agreement Form"]]]])]])))

(defmethod render-section "registration-information"
  [form student]
  (let [org-id (-> student :auth/user :user/organization :organization/id)]
    (r/as-element
      [section-container
       [f.unique/form
        {:entity student
         :key :student/id
         :unique-name "student_student-number"
         :attribute :student/student-number
         :extra {:organization/id org-id}}]])))

(defn- has-signature? [sig-file-entity file-id]
  (or sig-file-entity file-id))

(defn- dubai-section? [section]
  (some-> (:section/name section)
          (string/ends-with? "DXB")))

(defn- school-code* [student registration]
  (or (:registration/school-code registration)
      (:student/school-code student)))

(defn student-viewable-forms [student section registration]
  (let [school-code (school-code* student registration)]
    (cond
      (contains? #{"agca" "vecc" "saint-paul" "jrss" "hla"} school-code)
      [{:form "srk-registration-form"
           :title "Parent-Training Agreement"
           :pages "1-8"}
       {:form "deped-annex-3"
        :title "Affidavit of Undertaking"}
       {:title "SRK Student Handbook"
        :url "static/SRK_Student_Handbook.pdf"}
       {:title "Woodlands Student Handbook"
        :url "static/Woodlands_Student_Handbook.pdf"}]

      (dubai-section? section)
      [{:form "srk-registration-form-dubai"
        :title "Parent-Training Agreement - Dubai"
        :pages "2,3,6"}
       {:form "srk-texas-enrollment-form"
        :title "San Roque Kids Academy Agreement"}
       {:title "SRK Student Handbook"
        :url "static/SRK_Student_Handbook.pdf"}
       {:title "Woodlands Student Handbook"
        :url "static/Woodlands_Student_Handbook.pdf"}]

      (= "srk" school-code)
      [{:form "srk-registration-form"
        :title "Parent-Training Agreement"
        :pages "2,3,5"}
       {:form "srk-texas-enrollment-form"
        :title "San Roque Kids Academy Agreement"}
       {:title "SRK Student Handbook"
        :url "static/SRK_Student_Handbook.pdf"}
       {:title "Woodlands Student Handbook"
        :url "static/Woodlands_Student_Handbook.pdf"}]

      :else
      [])))

(defn file-id [registration]
  (:registration/signatory-person-signature-file-id registration))

(defn- preview-signature [{:keys [student file-id sig-upload-id show?]}]
  [:div {:style {:display (if show? "block" "none")}}
   [:img {:id "signature-preview"
          :src (or (e.student/signature-url student file-id) "#")
          ;; height should be synced with the .signature-pad height in .less
          :style {:height 200
                  :width "90%"}}]
   [ui/button {:type "text"
               :icon (r/as-element [icon/delete])
               :onClick (fn [e]
                          (rf/dispatch [::event.remove-signature sig-upload-id]))}]])

(defn- image-upload-signing [{:keys [sig-upload-id student]}]
  (let [sig-percent_ (rf/subscribe [:feat.presign-upload.sub/progress-percent sig-upload-id])
        upload-object_ (r/atom nil)]
    (if @sig-percent_
      [ui/progress {:percent @sig-percent_ :size "small"}]
      [ui/upload
       {:accept "image/*"
        :listType "picture"
        :showUploadList false
        :maxCount 1
        :beforeUpload (fn [file]
                        (if (< (utils/bytes->MB (g.object/get file "size")) config/max-upload-size-default)
                          true
                          (do
                            (rf/dispatch [:toast-notification {:type :error
                                                               :message (str "File should be less than " config/max-upload-size-default " MB")}])
                            ui/upload-list-ignore)))
        ;; https://github.com/react-component/upload#customrequest
        :customRequest (fn [^js upload-object]
                         (reset! upload-object_ upload-object)
                         (rf/dispatch [::event.upload-signature-start
                                       sig-upload-id
                                       upload-object
                                       (-> student :auth/user :user/id)]))}
       [ui/button {} "Upload Signature"]])))

(defn- in-app-signature-pad [{:keys [student]}]
  (let [sig-upload-id (random-uuid)
        sig-pad-el (atom nil)
        sig-state (r/atom {:empty? true})]
    [:div.signature-pad
      [:> react-signature
       {:ref #(reset! sig-pad-el %)
        :onBegin #(swap! sig-state assoc :empty? false)
        :canvasProps {:className "signature-pad__canvas"}}]
      [:div.signature-pad__actions
       [ui/button {:type "link"
                   :onClick (fn [e]
                              (.clear ^js @sig-pad-el)
                              (swap! sig-state assoc :empty? true))} "Clear"]
       [ui/button {:type "link"
                   :onClick (fn [e]
                              (some-> ^js @sig-pad-el
                                (.getCanvas)
                                (.toBlob
                                  (fn [blob]
                                    (rf/dispatch [::event.upload-in-app-signature-start sig-upload-id blob (-> student :auth/user :user/id)]))
                                  "image/png")))}
        "Confirm"]]]))

(defn- build-form-url [form params]
  (utils/form-url (:form form)
    (cond-> params
      (:pages form)
      (assoc :nativePageRanges (:pages form)))))

(defn- signature-form [form student]
  (r/with-let [sig-upload-id (random-uuid)
               registrations (rf/subscribe [:entity.student.sub/registrations (:student/id student)])
               pdf-viewer-state (r/atom nil)
               params {:user-id (-> student :auth/user :user/id)}
               viewer-student (rf/subscribe [:entity.viewer-student.sub/viewer-student])
               form-state (rf/subscribe [::subs.signature-form-state (:student/id student)])
               sig-file-entity_ (rf/subscribe [:feat.presign-upload.sub/upload-file-entity sig-upload-id])
               active-tab-key (r/atom "in-app")]
    [section-container
     [:<>
      [ui/paragraph "Please select the academic year and signatory, then press save. Then review the following forms before uploading e-signature."]
      [ui/space {:direction "vertical"}
       (into
         [ui/space
          {:direction "vertical"}]
         (map (fn [f]
                [ui/button {:type "link"
                            :onClick #(swap! pdf-viewer-state assoc
                                             :url (or (:url f)
                                                    (build-form-url f
                                                      (merge params
                                                            {:registration-id (:registration/id @form-state)})))
                                        :open? true)} (:title f)])
           (student-viewable-forms student (:section @viewer-student) @form-state)))
       [ui/radio-group {:value (get-in @form-state [:registration/id])
                        :onChange (fn [e]
                                    (rf/dispatch [::event.set-signature-form-state [:registration/id] (.. e -target -value)]))}
        (into [ui/space {:direction "vertical"}]
          (->> (sort-registrations @registrations)
            (map (fn [reg]
                   [ui/radio {:value (:registration/id reg)} (e.registration/registration-radio-text reg)]))))]]
      (when @pdf-viewer-state
        [:f> f.pdf-viewer/pdf-viewer {:url (:url @pdf-viewer-state)
                                      :open? true
                                      :close-on-overlay-click? true
                                      :on-close #(reset! pdf-viewer-state nil)}])
      [ui/form {:key (:registration/id @form-state)
                :ref #(rf/dispatch [::event.set-signature-form %])
                :style {:margin-top "64px"}
                :layout "vertical"
                :labelCol 24
                :wrapperCol 24
                :requiredMark true
                :onFinish (fn [_values]
                            ;; The rf app-db form-state is used instead of the antd form state
                            (let [kw-values (-> (select-keys @form-state [:registration/id
                                                                          :registration/signatory-person
                                                                          :registration/signatory-person-signature-file-id
                                                                          :registration/acknowledged-code-of-conduct?])
                                                (assoc :registration/signatory-person-signed-date (js/Date.)
                                                       :registration/signature-type @active-tab-key))]
                                (rf/dispatch [::event.save-registration-form kw-values])))}

       [ui/form-item
        {:label ""
         :rules [{:required false}]}
        [ui/checkbox
         {:checked (:registration/acknowledged-code-of-conduct? @form-state)
          :onChange #(rf/dispatch [::event.set-signature-form-state [:registration/acknowledged-code-of-conduct?] (.. % -target -checked)])}
         "I, the undersigned, hereby acknowledge that I have received, reviewed, and understand the Code of Conduct of SRK. I am aware of the expectations, values, and principles outlined in this code, and I agree to adhere to them while performing my duties and responsibilities within the organization."]]

       (when (:registration/acknowledged-code-of-conduct? @form-state)
         [:<>
          [ui/form-item
           {:label "Select Signatory"
            :rules [{:required true}]}
           [ui/select {:defaultValue (:registration/signatory-person @form-state)
                       :options [{:text "Father"
                                  :label "Father"
                                  :value "father"}
                                 {:text "Mother"
                                  :label "Mother"
                                  :value "mother"}
                                 {:text "Guardian"
                                  :label "Guardian"
                                  :value "guardian"}]
                       :onChange #(rf/dispatch [::event.set-signature-form-state [:registration/signatory-person] %])}]]
          ;; Need to display so that the element for sig preview is already in the DOM
          ;; once the side effect :preview/data-url is dispatched
          [preview-signature {:student student
                              :file-id (:registration/signatory-person-signature-file-id @form-state)
                              :sig-upload-id sig-upload-id
                              :show? (has-signature? @sig-file-entity_ (:registration/signatory-person-signature-file-id @form-state))}]
          (when-not (has-signature? @sig-file-entity_ (:registration/signatory-person-signature-file-id @form-state))
            [:> antd/Tabs {:activeKey @active-tab-key
                           :onChange #(reset! active-tab-key %)
                           :items [{:key "in-app"
                                    :label "Sign in-app"
                                    :children (r/as-element [in-app-signature-pad
                                                             {:student student
                                                              :onClear nil
                                                              :onConfirm nil}])}
                                   {:key "image-upload"
                                    :label "Upload Image"
                                    :children (r/as-element [image-upload-signing {:sig-upload-id sig-upload-id
                                                                                   :student student}])}]}])])
       [ui/form-item
        [ui/button {:type "primary"
                    :htmlType "submit"
                    :style {:marginTop "24px"}} "Save"]]]]]))

(defn- record->upload-id [record]
  (g.object/get record "upload-id"))

(defn- document-uploader [{:keys [customRequest document]}]
  (let [progress_ (rf/subscribe [:feat.presign-upload.sub/progress-percent (:upload-id document)])]
    (if @progress_
      [ui/progress {:percent @progress_ :size "small"}]
      [ui/upload
        {:accept "image/*,application/pdf"
         :showUploadList false
         :maxCount 1
         :beforeUpload (fn [file]
                         (if (< (utils/bytes->MB (g.object/get file "size")) config/max-upload-size-default)
                           true
                           (do
                             (rf/dispatch [:toast-notification {:type :error
                                                                :message (str "File should be less than " config/max-upload-size-default " MB")}])
                             ui/upload-list-ignore)))
         ;; https://github.com/react-component/upload#customrequest
         :customRequest customRequest}
       [ui/button {:type "link"} "Select File"]])))

(defn- table-actions [{:keys [document onDelete onDeleteSaved onSave onCancel]}]
  (let [file_ (rf/subscribe [:feat.presign-upload.sub/upload-file-entity (:upload-id document)])
        new? (= (:state document) "new")]
    [ui/space
     (when (and @file_ new?)
       [ui/button {:type "link"
                   :onClick #(onSave document @file_)} "Save"])
     (if new?
       [ui/button {:type "link"
                   :onClick #(onDelete document)} "Delete"]
       [ui/popconfirm
        {:title "Confirm Delete?"
         :description "This will permanently delete saved document."
         :onConfirm #(onDeleteSaved document)
         :okText "Yes"
         :cancelText "No"}
        [ui/button {:type "link"} "Delete"]])]))

(defn- new-document? [record]
  (= (g.object/get record "state") "new"))

(def document-types
  {"passport" "Passport",
   "visa" "Visa",
   "birth-certificate" "Birth Certificate",
   "emirates-id" "Emirates ID",
   "form-138" "Form 138 - Report Card",
   "form-137" "Form 137 - TOR",
   "certificate-good-moral" "Certificate of Good Moral",
   "photo" "Photo - (Passport Size)"
   "certificate-of-transfer" "Certificate of Transfer"
   "others" "Others"})

(defn validate-document [document]
  (cond-> {}
          (empty? (:description document))
          (assoc :description "Description is required.")

          (nil? (:document-type document))
          (assoc :document-type "Document type is required.")))

(defn supporting-documents-form [form student]
  (r/with-let [docs (->> (:student/supporting-documents student)
                         (map (fn [[k val]]
                                [k (assoc val :state "saved"
                                              :upload-id k)]))
                         (into {}))
               documents (r/atom docs)]
    (let [mobile? (responsive/useMediaQuery #js {:maxWidth 767})]
      [:<>
       [ui/title {:level 3} "Supporting Documents"]
       [:div
        [:div {:style {:display "flex"}}
         [ui/button {:style {:margin-left "auto"}
                     :onClick (fn [e]
                                (let [upload-id (random-uuid)]
                                  (swap! documents assoc upload-id {:upload-id upload-id
                                                                    :state "new"})))} "Add Document"]]
        [:br]
        [ui/table
         {:pagination false
          :rowKey "upload-id"
          :scroll (when mobile? {:x 500})
          :sticky mobile?
          :columns [{:title "Description"
                     :key "description"
                     :fixed "left"
                     :width (when mobile? 150)
                     :render (fn [text ^js record]
                               (r/as-element
                                 (if (new-document? record)
                                   [ui/input
                                    {:status (when (g.object/getValueByKeys record "errors" "description")
                                               "error")
                                     :onChange (fn [e]
                                                 (swap! documents assoc-in [(record->upload-id record) :description] (.. e -target -value)))}]
                                   (g.object/get record "description"))))}
                    {:title "Document Type"
                     :key "document-type"
                     :render (fn [text ^js record]
                               (r/as-element
                                 (if (new-document? record)
                                   [ui/select {:style {:width "100%"}
                                               :status (when (g.object/getValueByKeys record "errors" "document-type")
                                                         "error")
                                               :onChange (fn [val]
                                                           (swap! documents assoc-in [(record->upload-id record) :document-type] val))
                                               :options (->> document-types
                                                             (map (fn [[k v]] {:label v :value k}))
                                                             (sort-by :label))}]
                                   (get document-types (g.object/get record "document-type")))))}
                    {:title ""
                     :key "file"
                     :width (when mobile? 80)
                     :render (fn [text ^js record]
                               (r/as-element
                                 (let [upload-id (g.object/get record "upload-id")]
                                   (if (new-document? record)
                                     [document-uploader {:document (get @documents upload-id)
                                                         :customRequest (fn [^js upload-object]
                                                                          (rf/dispatch [::event.upload-document-start upload-id upload-object (-> student :auth/user :user/id)]))}]
                                     [ui/button {:type "link"
                                                 :onClick #(rf/dispatch [::event.download-document (g.object/get record "file-id")])} "Download"]))))}
                    {:title ""
                     :key "actions"
                     :width (when mobile? 80)
                     :render (fn [text ^js record]
                               (r/as-element
                                 (let [upload-id (g.object/get record "upload-id")]
                                   [table-actions {:document (get @documents upload-id)
                                                   :onDelete #(swap! documents dissoc (:upload-id %))
                                                   :onDeleteSaved (fn [document]
                                                                    (let [upload-id (:upload-id document)
                                                                          payload (-> (select-keys student [:student/id
                                                                                                            :student/supporting-documents])
                                                                                      (update-in [:student/supporting-documents] dissoc upload-id))]
                                                                      (swap! documents dissoc upload-id)
                                                                      (rf/dispatch [::event.save-form-mutation payload {:on-success [::event.save-document-form-mutation-success]}])))
                                                   :onSave (fn [document file]
                                                             (let [upload-id (:upload-id document)
                                                                   errors (validate-document document)]
                                                               (if (seq errors)
                                                                 (swap! documents assoc-in [upload-id :errors] errors)
                                                                 (let [document' (-> document
                                                                                     (assoc
                                                                                       :uploaded-date (js/Date.)
                                                                                       :file-id (:file/id file)))
                                                                       payload (-> {:student/id (:student/id student)
                                                                                    :student/supporting-documents (:student/supporting-documents student)}
                                                                                   (assoc-in [:student/supporting-documents upload-id] (dissoc document' :upload-id)))]
                                                                   (swap! documents assoc upload-id (assoc document' :state "saved"))
                                                                   (rf/dispatch [::event.save-form-mutation payload {:on-success [::event.save-document-form-mutation-success]}])))))}])))}]
          :dataSource (vals @documents)}]]])))

(defmethod render-section "signature"
  [form student]
  (r/as-element [section-container
                 [signature-form form student]]))

(defmethod render-section "supporting-documents"
  [form student]
  (r/as-element [ui/row
                 [ui/col {:span 24}
                  [:f> supporting-documents-form form student]]]))

(defn auto-form [form student opts]
  (let [disabled? (r/atom true)
        local-student (r/atom student)
        on-submit (or (:on-submit opts)
                    (fn [ctx form values]
                      (rf/dispatch [::event.submit-form ctx form values])))]
    (fn [form student opts]
      [f/form
       (assoc form
         :init-val @local-student
         :ui-schema {"ui:submitButtonOptions" {"props" {"disabled" @disabled?}}}
         :on-change (fn [ctx form values]
                      (reset! disabled? false)
                      (reset! local-student values))
         :on-submit (fn [ctx form values]
                      (reset! disabled? true)
                      (on-submit ctx form values)))])))

(defn- last-school-auto-form [form student]
  [auto-form form student {:on-submit (fn [ctx form values]
                                        (rf/dispatch [::event.submit-form ctx form values]))}])

(defmethod render-section "last-school-attended"
  [form student]
  (r/as-element
    [section-container
     [last-school-auto-form form student]]))

(defmethod render-section :default
  [form student]
  (r/as-element
    [section-container
     [auto-form form student {}]]))

(defn- sections->menu-items [sections student]
  (->> sections
       (map (fn [form]
              {:key (:name form)
               :label (:title form)
               :children (render-section form student)}))))

(defn student-profile-sections [{:keys [user-id]}]
  (let [student (rf/subscribe [:entity.student.sub/one-by-user-id user-id])
        sections @(rf/subscribe [::sub.profile-sections @student])
        mobile? (responsive/useMediaQuery #js {:maxWidth 767})]
    (when @student
      [ui/tabs {:theme "light"
                :tabPosition (if mobile? "top" "left")
                :items (sections->menu-items sections @student)
                :onChange #(rf/dispatch [:analytics/event "forms-section-changed" {:forms-section %}])}])))

(defn as-optional [schema registry]
  (malli.util/optional-keys schema nil {:registry registry}))

(defn as-optional-for-admin [schema role registry]
  ;; TODO: make it work with nested schema
  (if (contains? #{:super-admin :org-admin :school-admin} role)
    (as-optional schema registry)
    schema))

;; FIXME(jaime): just assume that they attended previously
;; a proper one should check subsequent AY
(defn- last-school-attended-within-org? [registrations]
  (> (count registrations) 1))

(rf/reg-sub
  ::sub.profile-sections
  (fn [[_ student]]
    [(rf/subscribe [:entity.schema.sub/registry])
     (rf/subscribe [:entity.current-user.sub/current-role])
     (rf/subscribe [:entity.student.sub/registrations (:student/id student)])])
  (fn [[registry role registrations] _]
    (when registry
      (let [omitted-forms (cond-> []
                            (= role :student)
                            (into ["user-account" "registration-information" "download-forms"])

                            ;; UPDATE: This needs to be shown, requested by admins.
                            ;; They need to see if important form fields are filled before signing student's clearance.
                            ;; (last-school-attended-within-org? registrations)
                            false
                            (into ["last-school-attended"]))]
        (->> [{:name "user-account"
               :title "User Account"}

              {:name "registration-information"
               :title "Registration Information"}

              {:name "learner-information"
               :title "Learner Information"
               :schema (as-optional-for-admin
                         [:map
                          :person/last-name
                          :person/first-name
                          [:person/middle-name {:optional true}]
                          [:person/extension-name {:optional true}]
                          :person/nickname
                          [:student/has-lrn? {:optional true}]
                          [:student/lrn {:optional true}]
                          :student/returning?
                          :person/gender
                          :person/date-of-birth
                          :person/place-of-birth
                          [:person/psa-birth-certificate-number {:optional true}]
                          :person/email
                          :person/mobile-number
                          :person/citizenship
                          :person/religion
                          [:person/home-address-ph {:title "Philippine Home Address"}]
                          [:person/home-address-uae {:title "UAE Home Address"}]
                          :person/primary-language
                          :person/mother-tongue
                          :person/height
                          :person/weight
                          :person/eye-color
                          :person/hair-color
                          :person/skin-color
                          :person/indigenous?
                          [:person/indigenous-group {:optional true}]
                          :person/beneficiary-4p?
                          [:person/household-number-4p {:optional true}]]
                         role
                         registry)}
              {:name "guardian"
               :title "Parent/Guardian Information"
               :schema (as-optional-for-admin
                         [:map
                          [:person/father {:title "Father's Name"}]
                          [:person/mother {:title "Mother's Name"}]
                          [:person/guardian {:optional true}]
                          [:student/close-kin {:optional true}]
                          :student/emergency-contact-person]
                         role
                         registry)}

              #_{:name "distance-learning-modalities"
               :title "Preferred Distance Learning Modalities"
               :schema (as-optional-for-admin
                         [:map
                          :student/preferred-distance-learning-modalities]
                         role
                         registry)}

              {:name "last-school-attended"
               :title "Last School Attended"
               :schema (as-optional-for-admin
                         [:map
                          :student/previous-school-attended]
                         role
                         registry)}

              {:name "medical-history"
               :title "Medical History"
               :schema (as-optional-for-admin
                         [:map
                          :student/medical-history]
                         role
                         registry)}

              {:name "senior-high-learner"
               :title "For Senior High School Only"
               :schema (as-optional-for-admin
                         [:map
                          :student/senior-high]
                         role
                         registry)}

              {:name "supporting-documents"
               :title "Supporting Documents"}

              {:name "signature"
               :title "Signature"}

              {:name "download-forms"
               :title "Download Forms"}]
             (remove (fn [form]
                       (some #(= (:name form) %) omitted-forms))))))))

(rf/reg-event-fx
  ::event.save-registration-form
  (fn [_ [_ values]]
    {:dispatch [::pathom/mutation {:op-name 'io.klei.lms.pathom.mutations/save-registration-form
                                   :params values
                                   :on-success [::event.save-registration-form-success]}]}))

(rf/reg-event-fx
  ::event.save-registration-form-success
  (fn [{:keys [db]} [_ response]]
    {:db (utils/add db response)
     :dispatch [:toast-notification {:type "success"
                                     :message "Form Saved."}]}))

(rf/reg-event-fx
  ::event.save-form-mutation
  (fn [_ [_ values {:keys [on-success]}]]
    {:dispatch [::pathom/mutation {:op-name 'io.klei.lms.pathom.mutations/save-form
                                   :params values
                                   :on-success (or on-success [::event.save-form-mutation-success])}]}))

(rf/reg-event-fx
  ::event.save-form-mutation-success
  (fn [{:keys [db]} [_ response]]
    {:dispatch [:toast-notification {:type "success"
                                     :message "Form saved"}]}))

(rf/reg-event-fx
  ::event.save-document-form-mutation-success
  (fn [{:keys [db]} [_ response]]
    {:db (utils/add db (get response 'io.klei.lms.pathom.mutations/save-form))
     :dispatch [:toast-notification {:type "success"
                                     :message "Document saved"}]}))

(rf/reg-event-fx
  ::event.submit-form
  (fn [{:keys [db]} [_ ctx form values]]
    (utils/set-date->str values :person/date-of-birth)
    {:dispatch [::pathom/mutation {:op-name 'io.klei.lms.pathom.mutations/save-form
                                   :params values
                                   :on-success [::event.submit-form-success form values]}]}))

(rf/reg-event-fx
  ::event.submit-form-success
  (fn [{:keys [db]} [_ form values response]]
    {:db (utils/add db values)
     :dispatch [:toast-notification {:type "success"
                                     :message "Form saved"}]}))
(rf/reg-event-fx
  ::event.upload-signature-start
  (fn [{:keys [db]} [_ upload-id ^js upload-object user-id]]
    {:fx [[:dispatch [:analytics/tags {:signature-type "image-upload"}]]
          [:dispatch [:feat.presign-upload.event/start
                      {:upload-id upload-id
                       :file (g.object/get upload-object "file")
                       :params {:upload-config-id :signature
                                :original-filename (g.object/getValueByKeys upload-object "file" "name")
                                :content-length (g.object/getValueByKeys upload-object "file" "size")
                                :content-type (g.object/getValueByKeys upload-object "file" "type")
                                :extra {:user-id user-id}}
                       :on-complete [::event.upload-signature-complete upload-object]}]]]}))

(rf/reg-event-fx
  ::event.upload-signature-complete
  (fn [{:keys [db]} [_ ^js upload-object _upload-config file-id response]]
    {:dispatch [::event.set-signature-form-state [:registration/signatory-person-signature-file-id] file-id]
     :preview/data-url ["signature-preview" (.-file upload-object)]}))

(rf/reg-event-fx
  ::event.upload-in-app-signature-start
  (fn [{:keys [db]} [_ upload-id ^js blob user-id]]
    {:fx [[:dispatch [:analytics/tags {:signature-type "in-app"}]]
          [:dispatch [:feat.presign-upload.event/start
                      {:upload-id upload-id
                       :file blob
                       :params {:upload-config-id :signature
                                :original-filename (str "in-app-" upload-id ".png")
                                :content-length (.-size blob)
                                :content-type (.-type blob)
                                :extra {:user-id user-id}}
                       :on-complete [::event.upload-in-app-signature-complete blob]}]]]}))

(rf/reg-event-fx
  ::event.upload-in-app-signature-complete
  (fn [{:keys [db]} [_ blob _upload-config file-id _response]]
    {:dispatch [::event.set-signature-form-state [:registration/signatory-person-signature-file-id] file-id]
     :preview/data-url ["signature-preview" blob]}))

(rf/reg-event-fx
  ::event.upload-document-start
  (fn [{:keys [db]} [_ upload-id ^js upload-object user-id]]
    {:dispatch [:feat.presign-upload.event/start
                {:upload-id upload-id
                 :file (g.object/get upload-object "file")
                 :params {:upload-config-id :user-document
                          :original-filename (g.object/getValueByKeys upload-object "file" "name")
                          :content-length (g.object/getValueByKeys upload-object "file" "size")
                          :content-type (g.object/getValueByKeys upload-object "file" "type")
                          :extra {:user-id user-id}}
                 :on-complete [::event.upload-document-complete upload-object]}]}))

;; TODO: Is this needed? Test support docs upload
(rf/reg-event-fx
  ::event.upload-document-complete
  (fn [{:keys [db]} [_ ^js upload-object _upload-config file-id response]]
    #_{:antd.form/set-fields-value [(::db.signature-form db) {(utils/nskw->json-key :registration/signatory-person-signature-file-id) file-id}]
     :preview/data-url ["signature-preview" (.-file upload-object)]}))

(rf/reg-event-fx
  ::event.set-signature-form
  (fn [{:keys [db]} [_ form]]
    {:db (assoc db ::db.signature-form form)}))

(rf/reg-event-fx
  ::event.remove-signature
  (fn [{:keys [db]} [_ upload-id]]
    (let [form (get db ::db.signature-form)]
      {:fx [[:dispatch [:db/dissoc-in [:feat.presign-upload.db/upload] upload-id]]
            [:dispatch [::event.set-signature-form-state [:registration/signatory-person-signature-file-id] nil]]]})))

(rf/reg-event-fx
  ::event.download-document
  (fn [{:keys [db]} [_ file-id]]
    (let [token (:entity.current-user.db/access-token db)]
      {:download-file {:uri (str config/api-endpoint "/file/" file-id "/download")
                       :token token}})))

(rf/reg-event-db
  ::event.set-signature-form-state
  (fn [db [_ path val]]
    (assoc-in db (into [::db.signature-form-state] path) val)))

(rf/reg-sub
  ::subs.signature-form
  (fn [db _]
    (get db ::db.signature-form)))

(rf/reg-sub
  ::subs.signature-form-state*
  (fn [db _]
    (get db ::db.signature-form-state {})))

(rf/reg-sub
  ::subs.signature-form-state
  (fn [[_ student-id]]
    [(rf/subscribe [::subs.signature-form-state*])
     (rf/subscribe [:entity.student.sub/registrations student-id])])
  (fn [[state registrations]]
    (let [reg-id (or (:registration/id state)
                   (:registration/id (first (sort-registrations registrations))))
          registration (get registrations reg-id)]
      (merge
        {:registration/acknowledged-code-of-conduct? (if (:registration/signatory-person-signature-file-id registration)
                                                       true
                                                       false)}
        (select-keys registration [:registration/id
                                   :registration/school-code
                                   :registration/signatory-person
                                   :registration/signatory-person-signature-file-id
                                   :registration/acknowledged-code-of-conduct?])
        state))))
